<template>
  <v-row 
    v-if="_files.length > 0" 
    class="mx-auto mb-5"
    align="center" 
    justify="center"
  >
    <v-col cols="auto">
      <v-btn color="primary" @click="uploadAllFiles()">
        <v-icon left>
          mdi-file-upload-outline
        </v-icon>
        Enviar tudo
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn color="default" @click="_files = []">
        <v-icon left>
          mdi-cancel
        </v-icon>
        Cancelar tudo
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-data-table
        id="upload-queue"
        :headers="[
          { text: 'Nome', value: 'name' }, 
          { text: 'Tamanho', value: 'size' }, 
          { text: 'Ações', value: 'actions' }
        ]"
        :items="_files"
        hide-default-footer
        hide-default-header
        disable-filtering
        disable-sort
        disable-pagination
        :mobile-breakpoint="740"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                id="upload-queue-name"
                class="text-truncate-column font-weight-medium"
                style="max-width: 25vw;"
                v-bind="attrs" v-on="on"
              >
                {{ item.name }}
              </div>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.size`]="{ item }">
          <span class="font-weight-medium">{{ item.size | bytesToSize }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn 
            small 
            color="primary" 
            class="mx-2"
            @click="uploadFile(item)">
            <v-icon left>mdi-file-upload-outline</v-icon>
            Enviar
          </v-btn>
          <v-btn
            small
            class="mx-2"
            @click="_files.splice(_files.findIndex(file => file.id === item.id), 1)"
          >
            <v-icon left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>

import FilesService from '@/services/FilesService.js';

export default {
  name: 'UploadQueue',
  props: {
    files: {
      type: Array,
      required: true,
    },
    uploadProgressData: {
      type: Object,
      required: true,
    }
  },
  computed: {
    _files: {
      get() {
        return this.files;
      },
      set(newValue) {
        this.$emit('update:files', newValue);
      }
    },
    _uploadProgressData: {
      get() {
        return this.uploadProgressData;
      },
      set(newValue) {
        this.$emit('update:uploadProgressData', newValue);
      }
    }
  },
  methods: {
    uploadFile(item) {

      if (!item.fileObj) return;

      const id = item.id;
      const name = item.name;

      this._files.splice(this._files.findIndex(file => file.id === id), 1);
      this.$set(this._uploadProgressData, id, { id, name });

      let formDataWithFile = new FormData();
      formDataWithFile.append('arquivo', item.fileObj);

      const onUploadProgress = (progressEvent) => {
        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        let isComputable = (progress == '100') ? false : progressEvent.lengthComputable;
        this.$set(this._uploadProgressData, id, { progress, isComputable, id, name });
      };

      FilesService
        .upload(formDataWithFile, onUploadProgress)
        .then(response => {
          this.$emit('file:uploaded', { 
            files: response.data.arquivos 
          });
        })
        .catch(err => {
          console.error(err);
          this.$toast.error(`Erro no envio do arquivo '${name}'`, '', { position: 'topRight' });
        })
        .finally(() => this.$delete(this._uploadProgressData, id));
    },
    uploadAllFiles() {
      this._files.forEach(file => {
        setTimeout(() => this.uploadFile(file), 250);
      });
    },
  }
}

</script>

<style>
  @media screen and (max-width: 740px) {
    #upload-queue #upload-queue-name {
      max-width: 50vw !important;
    }
  }
</style>